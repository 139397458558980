import { createGlobalStyle } from 'styled-components';
import { device } from './Breakpoints'

import './typography.css'

import variables from './variables.css'
// ^^ not explicitly used here but somehow used throughout elsewhere so do not delete


const GlobalStyles = createGlobalStyle`

    * {
        box-sizing: border-box;
        
    }
    a {
        text-decoration: none;
        color: inherit;
        cursor: pointer;
        &:visited {
            color: inherit;
        }
    }

    body, html {
        width: 100vw;
        max-width: 100vw;
        font-family: "Alte Haas", 'Helvetica Neue', 'Open Sans', -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    }

    html {
        font-weight: 500;
        color: var(--black);
        scroll-behavior: smooth;
    }

    .d-flex {
        display: flex;
    }
    main {
        position: relative;
        background-color: #fff;
    }

    img {
        max-width: 100%;
    }

    .container {
        padding-left: 72px;
        padding-right: 72px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        @media ${device.$medium} {
            max-width: 100vw;
            padding-left: 12px;
            padding-right: 12px;
        }
        @media ${device.$xlarge} {
            max-width: 1920px;
        }
    }

    .container--clear-icons {
        padding-left: 126px;
        padding-right: 126px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        @media ${device.$medium} {
            max-width: 100vw;
            padding-left: 12px;
            padding-right: 12px;
        }
        @media ${device.$xlarge} {
            max-width: 1920px;
        }
    }

    .masonry-grid {
        display: -webkit-box; /* Not needed if autoprefixing */
        display: -ms-flexbox; /* Not needed if autoprefixing */
        display: flex;
        justify-content: center;
        margin-left: -24px; /* gutter size offset */
        width: auto;
    }
    .masonry-grid__column {
        padding-left: 24px; /* gutter size */
        background-clip: padding-box;
    }
    
    /* Style your items */
    .masonry-grid__column > div { 
        margin-bottom: 24px;
    }

    .visually-hidden {
        position: absolute;
        left: -10000px;
        top: auto;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }

    form {
        input {
            border: 0;
            border-bottom: 1px solid #000;
            background-color: transparent;  
            -webkit-border-radius:0; 
            margin: 12px 0;
            padding: 0 0 4px 4px;
            &:focus {
                outline: none;
            }
        }
        label {
            display: block;
            font-weight: 700;
        }
        button {
            font-weight: 700;
            width: 100%;
            padding: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid var(--black);
        }
    }
    ::placeholder {
        font-size: 12px;
        color: #dadada;
    }

    button {
        &:focus {
            outline: none;
        }

        &.btn--none {
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            outline: inherit;
        }

        &.btn--styled {
            padding: 8px;
            white-space: nowrap;
            font-weight: 700;
            text-transform: uppercase;
            cursor: pointer;
            background-color: rgba(252, 217, 245, .95);
            transition: all .3s ease-in-out;

            &:hover {
                background-color: rgba(255, 163, 235, .95)
            }
        }
    }

    h1, h2, h3, 
    h4, h5, h6 {
        margin : 0;
    }

    .slick-slider {
        .slick-list {
            padding-right: 85px;
        }
        .slick-prev, .slick-next {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            &:before {
                display: none;
            }
        }
        .slick-prev {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='10' viewBox='0 0 24 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.274968 5.31019C0.275248 5.31042 0.275483 5.31069 0.275811 5.31092L5.17444 9.30825C5.54142 9.60771 6.135 9.60659 6.5003 9.30564C6.86555 9.00473 6.86414 8.51801 6.49715 8.21852L3.20822 5.53477L23.0625 5.53477C23.5803 5.53477 24 5.19062 24 4.76605C24 4.34149 23.5803 3.99733 23.0625 3.99733L3.20826 3.99733L6.49711 1.31358C6.86409 1.01409 6.8655 0.527377 6.50025 0.226462C6.13495 -0.07453 5.54133 -0.0755676 5.17439 0.223849L0.275765 4.22118C0.275483 4.22141 0.275248 4.22168 0.27492 4.22191C-0.0922522 4.52241 -0.0910792 5.0107 0.274968 5.31019Z' fill='black'/%3E%3C/svg%3E%0A");
            left: 24px;
        }
        .slick-next {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='10' viewBox='0 0 24 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.725 4.22191C23.7247 4.22168 23.7245 4.22142 23.7242 4.22118L18.8256 0.223848C18.4586 -0.075606 17.865 -0.0744916 17.4997 0.226462C17.1344 0.527377 17.1359 1.01409 17.5028 1.31358L20.7918 3.99733L0.9375 3.99733C0.419719 3.99733 0 4.34149 0 4.76605C0 5.19062 0.419719 5.53477 0.9375 5.53477L20.7917 5.53477L17.5029 8.21852C17.1359 8.51801 17.1345 9.00473 17.4997 9.30564C17.865 9.60663 18.4587 9.60767 18.8256 9.30826L23.7242 5.31092C23.7245 5.31069 23.7247 5.31042 23.7251 5.31019C24.0922 5.0097 24.0911 4.52141 23.725 4.22191Z' fill='black'/%3E%3C/svg%3E%0A");
            right: 24px;
        }
    }

    .sr-only {
        position:absolute;
        left:-10000px;
        top:auto;
        width:1px;
        height:1px;
        overflow:hidden;
    }
    
`

export default GlobalStyles;

