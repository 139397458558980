const size = {
    xsmall: '320px',
    small: '768px',
    medium: '768px',
    large: '1440px',
    xlarge: '1920px'
}

export const device = {
    $xsmall: `(max-width: ${size.xsmall})`,
    $small: `(max-width: ${size.small})`,
    $medium: `(max-width: ${size.medium})`,
    $large: `(max-width: ${size.large})`,
    $xlarge: `(min-width: ${size.xlarge})`,
}