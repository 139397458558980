import React from 'react'

import 'normalize.css'

import '../styles/initial.css'

import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import Seo from './SEO';


export default function Layout(props) {
    console.group("Site Credits");
    console.log('Designed & Developed by Matt Crowley');
    console.log('mshcrowley@gmail.com');
    console.groupEnd()
    return (
        <>
            <Seo />
            <GlobalStyles />
            <Typography />
            {props.children}
        </>
    )
}